import React from 'react';
import Link from 'next/link';
import { Row, Col } from 'reactstrap';
import { ContentIcon } from 'components/landings';
import PartnersKeyTalentIcon from 'public/assets/Partners-Key_Talent.svg';
import PartnersDiversityIcon from 'public/assets/Partners-Diversity.svg';
import PartnersWellbeingIcon from 'public/assets/Partners-Wellbeing.svg';
import PartnersChangeIcon from 'public/assets/Partners-Change.svg';
import PartnersLeadershipIcon from 'public/assets/Partners-Leadership.svg';
import PartnersAboutUsIcon from 'public/assets/Partners-About_Us.svg';

export default function InitialSection() {
  return (
    <>
      <Row className="max_desktop_width_70 d-flex justify-content-center pb-5">
        <div>
          <p style={{ fontSize: '1.5rem' }} className="text-center pt-3 ">
            Network Leader provides organizations with data-driven metrics and
            insights to measure and improve their key people outcomes across 5
            key factors.
            {' '}
            <Link href="/diagnostic">
              <a>The Leader Network Diagnostic</a>
            </Link>
            {' '}
            draws from 30 years of research in network science to provide proven
            insights and actions across each factor. With almost zero set up
            time (you can start tomorrow), minimal time investment (less than 20
            min to get started), and extensibility (from 10 to 10,000+ people)
            this is an ideal solution to maximize scale and impact.
          </p>
        </div>
        <Col className="landing_display d-flex justify-content-center col-12 col-lg-5" />
      </Row>
      <Row className="max_desktop_width_60 d-flex justify-content-center pb-5">
        <Col className="col-12 col-md-6 col-lg-4 pb-5">
          <ContentIcon
            icon={PartnersKeyTalentIcon}
            learnMore
            learnMoreUrl="#key-talent"
            color="orange"
            text={<p><strong>Key Talent Retention</strong></p>}
            subtext={(
              <p style={{ maxWidth: '12rem' }} className="text-center">
                Invest in & monitor the health of key talent networks
              </p>
            )}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-4 pb-5">
          <ContentIcon
            icon={PartnersDiversityIcon}
            learnMore
            learnMoreUrl="#belonging"
            color="blue"
            text={
              <p><strong>Belonging, Diversity, & Inclusion</strong></p>
            }
            subtext={(
              <p style={{ maxWidth: '12rem' }} className="text-center">
                Help sponsors diversify their networks
              </p>
            )}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-4 pb-5">
          <ContentIcon
            icon={PartnersWellbeingIcon}
            learnMore
            learnMoreUrl="#wellbeing"
            color="green"
            text={<p><strong>Wellbeing</strong></p>}
            subtext={(
              <p style={{ maxWidth: '12rem' }} className="text-center">
                Ensure your talent has the social support to stay resilient
              </p>
            )}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-4 pb-5">
          <ContentIcon
            icon={PartnersChangeIcon}
            learnMore
            learnMoreUrl="#innovation"
            color="orange"
            text={<p><strong>Change & Innovation</strong></p>}
            subtext={(
              <p style={{ maxWidth: '12rem' }} className="text-center">
                Activate your change and innovation agents
              </p>
            )}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-4 pb-5">
          <ContentIcon
            icon={PartnersLeadershipIcon}
            learnMore
            learnMoreUrl="#leadership"
            color="blue"
            text={<p><strong>Leadership Development</strong></p>}
            subtext={(
              <p style={{ maxWidth: '12rem' }} className="text-center">
                Help sponsors diversify their networks
              </p>
            )}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-4 pb-5">
          <ContentIcon
            icon={PartnersAboutUsIcon}
            learnMore
            learnMoreUrl="/about"
            color="green"
            text={<p><strong>About Us</strong></p>}
            subtext={(
              <p style={{ maxWidth: '12rem' }} className="text-center">
                Learn about the team behind Network Leader
              </p>
            )}
          />
        </Col>
      </Row>
    </>
  );
}
