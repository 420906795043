var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ec715c23b026547411079dffd7a2a00e139cb486"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

const Sentry = require('@sentry/nextjs');

Sentry.init({
  enabled: true,
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.LND_ENV,
  release: process.env.NEXT_PUBLIC_COMMIT_SHA,
});
