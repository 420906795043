import Link from 'next/link';
import React from 'react';
import PropTypes from 'prop-types';

// Refer to landings.scss for class style defs
const colorClassMap = {
  blue: 'blue-button',
  orange: 'orange-button',
  purple: 'purple-button',
  green: 'green-button',
  lndBlue: 'blue-button',
  lndOrange: 'orange-button',
  lndPurple: 'purple-button',
  lndGreen: 'green-button',
};

export default function LandingButton({
  color = 'orange',
  url,
  text,
  newTab = null,
}) {
  const colorClass = colorClassMap[color];
  const isExternal = url.indexOf('http') === 0;
  let isNewTab = newTab;
  if (isNewTab === null && isExternal) {
    isNewTab = true;
  }
  const buttonProps = {};
  if (isNewTab) {
    buttonProps.target = '_blank';
    buttonProps.rel = 'noopener noreferrer';
  }
  let linkElt = (
    <a
      target={buttonProps.target}
      rel={buttonProps.rel}
      href={url}
      role="button"
      className={`m-auto ${colorClass}`}
    >
      {text}
    </a>
  );
  if (isExternal) {
    linkElt = <Link href={url}>{linkElt}</Link>;
  }
  return (
    <div
      className="d-flex justify-content-center align-items-center landing-button-container"
      style={{ textAlign: 'center' }}
    >
      {linkElt}
    </div>
  );
}

LandingButton.propTypes = {
  color: PropTypes.string,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  newTab: PropTypes.bool,
};

LandingButton.defaultProps = {
  color: 'orange',
  newTab: null,
};
