import { gql } from '@apollo/client';
import { useApolloClient } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addCohortStatistics } from 'lib/redux/actions';
import { getCohortStatistics } from 'lib/redux/selectors';

const GET_COHORT_STATISTICS = gql`
  query cohortStatisticsObj($cohort: String) {
    cohortStatistics(cohort: $cohort) {
      retake {
        networkSizeAverage {
          lastRetake
          penultimateRetake
          difference
        }
        opennessAverages {
          too_open
          very_open
          open
          somewhat_closed
          closed
          very_closed
        }
        depthAverages {
          very_distant
          not_close
          close
          very_close
        }
        boundariesAverages {
          demographic
          geographic
          horizontal
          stakeholder
          vertical
        }
        resourceAverages {
          career
          friend
          growth
          innovators
          mentoring
          motivators
          support
          work
        }
      }
      networkSizeAverage
      opennessAverages {
        too_open
        very_open
        open
        somewhat_closed
        closed
        very_closed
      }
      depthAverages {
        very_distant
        not_close
        close
        very_close
      }
      boundariesAverages {
        demographic
        geographic
        horizontal
        stakeholder
        vertical
      }
      resourceAverages {
        career
        friend
        growth
        innovators
        mentoring
        motivators
        support
        work
      }
    }
  }
`;

export default function useCohortStatistics() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const storedStatistics = useSelector(getCohortStatistics);
  const dispatch = useDispatch();
  const client = useApolloClient();
  const router = useRouter();
  const { cohort } = router.query;

  const validate = (statistics) => {
    const validStatistics =
      Object.keys(statistics).filter((key) => {
        if (key === 'retake') return false;
        return statistics[key] === null;
      }).length === 0;

    if (validStatistics) {
      setStatistics(statistics);
      setIsLoading(false);
    } else {
      setIsError(true);
    }
  };

  useEffect(() => {
    const statisticsLoadedOnAdminPage =
      Object.keys(storedStatistics).length > 0 &&
      statistics.length === 0 &&
      cohort === storedStatistics.cohortName;

    const loadStatisticsOnThisPage =
      statistics.length === 0 && cohort && cohort !== storedStatistics.cohortName;

    if (statisticsLoadedOnAdminPage) {
      validate(storedStatistics);
    } else if (loadStatisticsOnThisPage) {
      const getCohortStatistics = async () => {
        client
          .query({
            query: GET_COHORT_STATISTICS,
            variables: {
              cohort,
            },
          })
          .then(({ data: { cohortStatistics } }) => {
            dispatch(addCohortStatistics(cohortStatistics, cohort));
            validate(cohortStatistics);
          })
          .catch((err) => {
            setIsError(true);
          });
      };
      getCohortStatistics();
    }
  }, [storedStatistics, statistics, cohort]);

  return [isError, isLoading, statistics];
}
