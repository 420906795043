import { gql } from '@apollo/client';
import { useApolloClient } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSurvey } from 'lib/hooks/useSurvey';
import { useDispatch } from 'react-redux';
import { updateResults, addSurveys } from 'lib/redux/actions';

const GENERATE_REPORT = gql`
  query report($surveyId: ID!) {
    report(surveyId: $surveyId) {
      boundaries {
        individual {
          demographic
          geographic
          horizontal
          stakeholder
          vertical
        }
        overall
      }
      deep {
        close
        not_close
        very_close
        very_distant
      }
      open
      resources {
        individual {
          career
          friend
          growth
          innovators
          mentoring
          motivators
          support
          work
        }
        overall
      }
    }
  }
`;

function transformSurveyToGraphQLStructure(survey) {
  const newSurvey = JSON.parse(JSON.stringify(survey));

  if (newSurvey.stakeholders !== undefined) {
    delete newSurvey.stakeholders;
  }

  if (newSurvey.you.careerSatisfaction?.__typename !== undefined) {
    delete newSurvey.you.careerSatisfaction.__typename;
  }

  if (newSurvey.__typename !== undefined) {
    delete newSurvey.__typename;
  }

  if (newSurvey.additionalStakeholders !== undefined) {
    delete newSurvey.additionalStakeholders;
  }
  /* TODO: study possibility of disabling typename on apollo cache or adding it to every object on schema :
  https://www.apollographql.com/docs/react/caching/cache-configuration/ */
  if (newSurvey.you.__typename !== undefined) {
    delete newSurvey.you.__typename;
  }

  if (newSurvey.network.__typename !== undefined) {
    delete newSurvey.network.__typename;
  }

  newSurvey.network.forEach((element) => {
    if (element.__typename !== undefined) {
      delete element.__typename;
    }
  });

  return newSurvey;
}

const useGenerateReport = () => {
  const client = useApolloClient();
  const {
    query: { id: surveyId },
  } = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const { getSurvey } = useSurvey();
  const [cohortId, setCohortId] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const { result: surveyResult, errors: surveyErrors } = getSurvey({
    id: surveyId,
  });

  useEffect(() => {
    setIsLoading(true);
    if (surveyId && surveyResult) {
      dispatch(addSurveys([surveyResult.getSurvey]));
      let survey = surveyResult.getSurvey;
      setCohortId(survey.cohort_id);
      setUserEmail(survey.survey.you.email);
      const generateReport = async () => {
        survey = {
          cohort: survey.cohort_id,
          ...transformSurveyToGraphQLStructure(survey.survey),
        };

        const generatedReport = await client.query({
          query: GENERATE_REPORT,
          variables: {
            surveyId,
          },
        });

        if (generatedReport.errors) {
          setIsError(true);
        } else {
          const {
            data: { report },
          } = generatedReport;
          dispatch(updateResults({ responseBody: report }));
        }
        setIsLoading(false);
      };
      generateReport();
    }
    if (surveyErrors) {
      setIsLoading(false);
      setIsError(true);
    }
  }, [surveyResult, surveyErrors]);
  return [isLoading, isError, surveyId, cohortId, userEmail];
};

export default useGenerateReport;
