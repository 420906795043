import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { NetworkLeaderLogo } from 'components/landings/survey';
import WebLink from 'components/WebLink';
import Link from 'next/link';
import { useCohortCodeUrl } from 'lib/hooks';
import emailIcon from 'public/assets/LND-website-email-icon.svg';
import linkedInIcon from 'public/assets/linkedin.svg';
import { contactUsIndividualLink } from 'lib/constants';
import { LoginLink } from './AccountButton';

export default function Footer({ auth }) {
  const currentYear = new Date().getFullYear();
  return (
    <Container fluid className="footer mt-6">
      <Row className="max_desktop_width_80">
        <Col className="col-12 col-md-4 col-lg-3 d-flex flex-column align-items-start pb-3 pt-4">
          <Link href={useCohortCodeUrl('/')}>
            <NetworkLeaderLogo />
          </Link>

          <div className="d-flex align-items-end justify-content-center footer_link footer_link_none">
            <img
              className="me-4"
              style={{ maxWidth: '2rem', margin: '1rem 1rem 0 1.5rem' }}
              src={emailIcon.src}
              alt="email"
            />
            <a href="mailto:info@NetworkLeader.com">info@NetworkLeader.com</a>
          </div>
          <div className="d-flex align-items-end justify-content-center footer_link footer_link_none">
            <img
              className="me-4"
              style={{ maxWidth: '2rem', margin: '1rem 1rem 0 1.5rem' }}
              src={linkedInIcon.src}
              alt="linkedin"
            />
            <a href="https://www.linkedin.com/company/network-leader">LinkedIn</a>
          </div>
        </Col>
        <Col className="d-flex col-9 col-md-7 col-lg-9 align-items-start justify-content-start flex-wrap pt-4">
          <div className="px-2 footer_link">
            <WebLink href="/">Home</WebLink>
          </div>
          <div className="px-2 footer_link">
            <WebLink href="/legal/privacy-policy">Privacy Policy</WebLink>
          </div>
          <div className="px-2 footer_link">
            <WebLink href="/legal/terms-conditions">Terms of Service</WebLink>
          </div>
          <div className="px-2 footer_link">
            <WebLink href="/articles">Articles</WebLink>
          </div>
          <div className="px-2 footer_link">
            <Link href={contactUsIndividualLink}>Contact Us</Link>
          </div>
          <div className="px-2 footer_link footer_link_none">
            <a
              className="p-0 text-black border-0 inline bg-transparent font-base"
              href="/api/auth/logout"
            >
              Logout
            </a>
          </div>
        </Col>
        <Col className="col-12">
          <p className="footer_col_text">Copyright &copy; {currentYear} Network Leader</p>
        </Col>
      </Row>
    </Container>
  );
}

Footer.propTypes = {
  auth: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
};
