import { useEffect } from 'react';
import { addCohortCode } from 'lib/redux/actions';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

const useCohortCode = (save = true) => {
  const router = useRouter();
  const { code } = router.query;

  const dispatch = useDispatch();
  useEffect(() => {
    if (code && save) {
      dispatch(addCohortCode(code));
    }
  }, [code]);

  return code;
};

const useCohortCodeUrl = (baseUrl) => {
  const code = useCohortCode();
  return code ? `${baseUrl}?code=${code}` : baseUrl;
};

export { useCohortCode, useCohortCodeUrl };
