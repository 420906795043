import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Form, FormGroup, Input, Label, Col } from 'reactstrap';
import diagnosticLogo from 'public/assets/LND-website-LND-logo.svg';
import { useCohortCodeUrl } from 'lib/hooks';
import WebLink from 'components/WebLink';
import NWLSpinner from 'components/NWLSpinner';
import { useDispatch } from 'react-redux';
import { saveConsent } from 'lib/redux/actions';

const tosLink = (
  <WebLink target="_blank" href="/legal/terms-conditions">
    Terms of Service
  </WebLink>
);

const privacyPolicyLink = (
  <WebLink target="_blank" href="/legal/privacy-policy">
    Privacy Policy
  </WebLink>
);

export default function Diagnostic() {
  const takeSurveyLink = useCohortCodeUrl('survey/start');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [showMessage, setShowMessage] = useState(false);

  const router = useRouter();

  const [checkBoxes, setCheckboxes] = useState({
    tosAndPrivacyPolicy: {
      text: 'I accept the Terms of Service and Privacy Policy of Network Leader',
      label: (
        <>
          I accept the {tosLink} and {privacyPolicyLink} of Network Leader
        </>
      ),
      checked: false,
    },
    personalInfoCollection: {
      text: 'I consent to have Network Leader collect my personal information for the purpose of providing this service ',
      checked: false,
    },
    reportingServices: {
      text: 'I consent to have my data used in anonymous & aggregated reporting as part of the research & benchmarking services for my cohort and/or my company',
      checked: false,
    },
    consentWithdrawalInfo: {
      text: 'I acknowledge that I may withdraw my consent at any time by contacting privacy@networkleader.com and requesting the removal of my data',
      checked: false,
    },
  });

  const updateCheckbox = (key) => {
    setCheckboxes({
      ...checkBoxes,
      [key]: {
        ...checkBoxes[key],
        checked: !checkBoxes[key].checked,
      },
    });
  };

  const validateCheckboxes = () => {
    const checks = Object.getOwnPropertyNames(checkBoxes).map(
      (e) => checkBoxes[e].checked
    );
    return checks.find((e) => e === false);
  };

  const takeSurvey = async () => {
    setLoading(true);
    if (validateCheckboxes() === false) {
      setShowMessage(true);
      setLoading(false);
      return;
    }

    const consentGiven = {
      ...checkBoxes,
      tosAndPrivacyPolicy: {
        text: checkBoxes.tosAndPrivacyPolicy.text,
        checked: checkBoxes.tosAndPrivacyPolicy.checked,
      },
    };

    dispatch(saveConsent(consentGiven));

    router.push(takeSurveyLink);
  };

  if (loading) return <NWLSpinner fillView actionText="loading" />;
  return (
    <div
      className="diagnostic pb-5 px-4"
      style={{ maxWidth: '55rem', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <div className="d-flex py-6 justify-content-center">
        <img
          src={diagnosticLogo.src}
          alt="diagnostic logo"
          style={{ width: '18.5rem' }}
        />
      </div>
      <Col className="col-12 pb-4">
        <h3>
          The Leader Network Diagnostic helps you visualize, interpret and capitalize on
          your core professional network.
        </h3>
      </Col>
      <Form className="col-12 pb-4">
        {Object.getOwnPropertyNames(checkBoxes).map((e) => (
          <FormGroup check className="py-2">
            <Input
              type="checkbox"
              id={e}
              checked={checkBoxes[e].checked}
              onChange={() => updateCheckbox(e)}
            />
            <Label for={e} check>
              {checkBoxes[e].label || checkBoxes[e].text}
            </Label>
          </FormGroup>
        ))}
      </Form>
      {showMessage && (
        <div className="italic pb-4">
          *All boxes must be selected in order to provide this service from Network
          Leader.
        </div>
      )}
      <div className="d-flex justify-content-center">
        <Button
          color="back-button"
          className="bg-back-button mx-auto"
          disabled={validateCheckboxes() === false && showMessage}
          onClick={takeSurvey}
        >
          Take the Diagnostic
        </Button>
      </div>
    </div>
  );
}
