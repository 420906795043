import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Button } from 'reactstrap';

function AccountButton({ auth, buttonColor, loginText }) {
  const router = useRouter();

  // if (auth && router.pathname === '/authorize') {
  //   auth.handleAuthentication();
  //   return <div id="main-content" />;
  // }

  if (auth && auth.isAuthenticated()) {
    return (
      <Button
        className="d-flex align-items-center justify-content-center"
        onClick={() => auth && auth.logout()}
      >
        LOGOUT
      </Button>
    );
  }

  return (
    <Button
      color={buttonColor}
      className="d-flex align-items-center justify-content-center mt-lg-2"
      onClick={() => {
        auth?.login();
      }}
    >
      {loginText}
    </Button>
  );
}

AccountButton.propTypes = {
  auth: PropTypes.object.isRequired,
  buttonColor: PropTypes.string.isRequired,
  loginText: PropTypes.string.isRequired,
};

export function LoginLink({ auth }) {
  const router = useRouter();
  // if (auth && router.pathname === '/authorize') {
  //   auth.handleAuthentication();
  //   return <div id="main-content" />;
  // }

  if (auth && auth.isAuthenticated()) {
    return (
      <button
        color="none"
        type="button"
        className="p-0 text-black border-0 inline bg-transparent font-base"
        onClick={(e) => {
          e.preventDefault();
          auth.logout();
          return false;
        }}
      >
        Logout
      </button>
    );
  }

  return (
    <button
      color="none"
      type="button"
      className="p-0 text-black border-0 inline bg-transparent font-base"
      onClick={(e) => {
        e.preventDefault();
        sessionStorage.setItem('__LND_pathBeforeLogin', router.asPath);
        auth.login();
        return false;
      }}
    >
      Login
    </button>
  );
}

LoginLink.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
  }).isRequired,
};

export default AccountButton;
