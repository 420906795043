import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import Link from 'next/link';
import { Row, Col, Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap';

import { NetworkLeaderLogo } from 'components/landings/survey';
import WebLink from 'components/WebLink';
import { useCohortCodeUrl } from 'lib/hooks';
import { contactUsIndividualLink } from 'lib/constants';
// import { AccountButton } from './AccountButton';
import BurgerMenu from 'components/Header/BurgerMenu';

export default function Header() {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <>
      <div
        className="d-none d-lg-block"
        style={{
          height: '55px',
          backgroundColor: 'white',
          width: '100%',
          position: 'absolute',
          right: '0',
          top: '45px',
          zIndex: '-1',
        }}
      />
      <Row className="header max_desktop_width_70 w-100 pt-0">
        <Col className="col-8 col-lg-3 d-flex align-items-start pb-3 pt-3 pt-lg-5">
          <Link href={useCohortCodeUrl('/')}>
            <NetworkLeaderLogo />
          </Link>
        </Col>

        <Col
          className="d-none d-lg-flex col-9 align-items-center justify-content-end
       flex-wrap pt-lg-2"
        >
          <div className="ps-3 py-1 header_link">
            <Link href="/">ACCOUNT</Link>
          </div>
          <div className="px-3 py-1 header_link">
            <a target="_blank" rel="noreferrer" href={contactUsIndividualLink}>
              CONTACT
            </a>
          </div>
          {/* {isDesktop ? <AccountButton auth={auth} /> : null} */}
        </Col>
        <Col
          style={{ position: 'absolute', top: '1%', right: '0' }}
          className="col-2 d-lg-none p-0"
        >
          {!collapsed && (
            <div
              onClick={() => setCollapsed(true)}
              role="button"
              aria-label="Collapse"
              tabIndex={0}
              onKeyPress={() => setCollapsed(true)}
              style={{
                position: 'fixed',
                height: '100%',
                width: '100%',
                zIndex: '199',
                top: '0',
                left: '0',
                backgroundColor: 'transparent',
              }}
            />
          )}
          <Navbar
            style={{ zIndex: '200' }}
            color="faded"
            light
            className="justify-content-end"
          >
            <NavbarToggler
              style={{ backgroundColor: 'rgb(255, 255, 255)' }}
              onClick={toggleNavbar}
              className="me-2"
            />
            <Collapse
              className="ps-4"
              style={{
                minWidth: '150px',
                color: '#333',
                backgroundColor: 'rgb(255, 255, 255)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.35)',
                border: '0',
                borderRadius: '2px',
                lineHeight: '34px',
                zIndex: '200',
              }}
              isOpen={!collapsed}
              navbar
            >
              <Nav navbar>
                <BurgerMenu onClick={() => setCollapsed(true)} />
                {/* {!isDesktop ? <AccountButton auth={auth} /> : null} */}
              </Nav>
            </Collapse>
          </Navbar>
        </Col>
      </Row>
      <CookieConsent
        // disableStyles
        buttonClasses="cookie-button-class"
        containerClasses="cookie-container-class"
        contentClasses="cookie-content-class"
        buttonText="Accept"
        buttonStyle={{
          backgroundColor: '#f59120',
          color: 'white',
          borderRadius: '10px',
          padding: '10px 15px',
        }}
        style={{
          backgroundColor: '#828282',
          width: '80%',
          left: 'auto',
          bottom: '20px',
          borderRadius: '10px',
          boxShadow: '0 24px 32px rgba(0,0,0,.1),0 8px 32px rgba(0,0,0,.1)',
          alignItems: 'flex-start',
        }}
      >
        <p>
          We use cookies to provide site functionality, improve the user experience, and
          analyze website traffic. By clicking{' '}
          <strong>
            <i>Accept</i>
          </strong>
          , you agree to our website's cookie policy. You can view and set preferences in
          the <WebLink href="/legal/cookie-policy">Cookie Policy</WebLink>.
        </p>
      </CookieConsent>
    </>
  );
}
